import React from 'react';
import PageHeader from "../../components/pageheader/PageHeader";
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import gameData from "../../datas/gamesData.json";
import GameCard from "../../components/gamecard/GameCard";
import "./Portfolio.css";
import HelmetData from '../../components/helmet/HelmetData';

export default function Portfolio() {
  return (
    <>
      <HelmetData
        title="Portfolio"
        descripton="Welcome to my... wizard cave? Over here you can find all the games that I have made for either clients, game jams or just personal games."
        canonicalLink={"https://www.nabilshelim/#/portfolio"}
      />
      <div>
        {gameSection("client", "Client Projects")}
        {gameSection("jams", "Game Jam Games")}
        {gameSection("personal", "Personal Games")}
      </div>
    </>
  )
}

function gameSection(type, heading) {
  return <>
    <PageHeader title={heading} icon={faGamepad} />
    <div className="portfolio-list">
      {
        getGames(type).sort((a, b) => 
        {
          return new Date(b.toDate) - new Date(a.toDate);
        }
        ).map((game, i) => {
          return (
            <GameCard
              key={i}
              id={game.id}
              category={game.category}
              markdownFileName={game.markdownFileName}
              title={game.title}
              icon={game.icon}
              header={game.header}
              screenshots={game.screenshots}
              link={game.link}
              engine={game.engine}
              language={game.language}
            />
          )
        })
      }
    </div>
  </>
}

function getGames(filterKeyWord) {
  if (gameData.length === 0) {
    return null;
  }
  return gameData.filter(post => post.category === filterKeyWord);
}