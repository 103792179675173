import React from "react";
import { useNavigate } from "react-router-dom";

import "./GameCard.css";

function GameCard(prop) {
    const navigate = useNavigate();
    const path = "./content/games/" + prop.category + "/" + prop.markdownFileName + "/" + prop.icon

    const engineIcon = "./content/icons/" + prop.engine + ".png"
    const languageIcon = "./content/icons/" + prop.language + ".png"

    function onClick() {
        navigate(`/portfolio/${prop.id}`);
    }

    return (
        <div onClick={onClick} className="gamecard card">
            <div className="tech-stack">
                <img className="tech-icon" src={engineIcon} alt="" />
                <img className="tech-icon" src={languageIcon} alt="" />
            </div>
            
            <img className="game-icon" src={path} alt="" />
            <div className="card-title"><h2>{prop.title}</h2></div>
        </div>
    )
}

export default GameCard;